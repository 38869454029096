import styled from 'styled-components';

export const PromocodeWrapper = styled.div`
  background-color: #F1F5F7;
  border-radius: 8px;
  padding: 20px;
`;


export const PromocodeTitle = styled.div`
  text-align: center;
  width: 100%;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 20px;
`;

export const PromocodeEnterWrapper = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
`;


export const PromocodeInputWrapper = styled.div`
  flex: 1;
  position: relative;
`;

export const PromocodeInput = styled.input<{ isError: boolean }>`
  height: 68px;
  padding: 0;
  width: 100%;
  border: 1px solid #B5B5B5;
  border-color: ${props => props.isError && 'rgb(225, 74, 85)'};
  border-radius: 8px;
  text-align: center;
  color: #809FB8;
  font-size: 16px;
`;

export const PromocodeButton = styled.button`
  border: 2px solid #381DDB;
  color: #381DDB;
  border-radius: 12px;
  background-color: #fff;
  font-size: 20px;
  font-weight: 500;
  padding: 0 19px;
  cursor: pointer;
  text-decoration: none;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
`;


export const ErrorIcon = styled.div`
  position: absolute;
  right: 10px;
  top: 24px;
  cursor: pointer;
  display: block;
`;

export const PromocodeError = styled.div`
	margin-bottom: 10px;
	text-align: center;
	color: #E14A55;
`;

export const PromocodeDiscountSuccess = styled.div`

`;

export const PromocodeSuccessTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 14px;
  padding: 0 15px;
`;
export const PromocodeSuccessView = styled.div`
  background-color: #381DDB;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-radius: 8px;
  
  span {
    font-size: 26px;
    font-weight: bold;
  }
`;
