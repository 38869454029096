import React, { FC, useState } from 'react';
import {
	ErrorIcon,
	PromocodeButton,
	PromocodeEnterWrapper,
	PromocodeInput,
	PromocodeInputWrapper,
	PromocodeError,
	PromocodeDiscountSuccess,
	PromocodeSuccessTitle,
	PromocodeSuccessView,
} from './OrderApplyPromo.styled';
import { PaymentApi } from '../../payment-api';
import { useParams } from 'react-router';
import { PaymentStore } from '../../payment.store';
import { SuccessPromoIcon } from '../../../core/icons/SuccessPromoIcon';

export const OrderApplyPromo: FC<{ store: PaymentStore }> = ({store}) => {
	const [promocode, setPromocode] = useState('');
	const [isError, setIsError] = useState(false);
	const params = useParams();
	const id = (params.id ?? 0) as number;

	const sendPromocode = async () => {
		try {
			const result = await PaymentApi.sendPromocode(id, promocode);
			if (result.isValid) {
				store.getPayment(id, '', promocode);
			} else {
				setIsError(true);
			}
		} catch (e) {
			console.error('eee', e);
		}
	};

	const clearInput = () => {
		setPromocode('');
		setIsError(false);
	}

	return <PromocodeEnterWrapper>
		{!store.payment?.discount && <PromocodeInputWrapper>
			<PromocodeInput placeholder={'Введите код'} isError={isError} value={promocode} onChange={(e) => {
				setIsError(false);
				setPromocode(e.target.value);
			}}/>

			{isError && <ErrorIcon onClick={clearInput}>
                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_599_4955)">
                        <path
                            d="M2.5 10.6719C2.5 11.6568 2.69399 12.6321 3.0709 13.542C3.44781 14.4519 4.00026 15.2787 4.6967 15.9752C5.39314 16.6716 6.21993 17.2241 7.12987 17.601C8.03982 17.9779 9.01509 18.1719 10 18.1719C10.9849 18.1719 11.9602 17.9779 12.8701 17.601C13.7801 17.2241 14.6069 16.6716 15.3033 15.9752C15.9997 15.2787 16.5522 14.4519 16.9291 13.542C17.306 12.6321 17.5 11.6568 17.5 10.6719C17.5 9.68696 17.306 8.71169 16.9291 7.80175C16.5522 6.89181 15.9997 6.06501 15.3033 5.36857C14.6069 4.67213 13.7801 4.11969 12.8701 3.74278C11.9602 3.36587 10.9849 3.17188 10 3.17188C9.01509 3.17188 8.03982 3.36587 7.12987 3.74278C6.21993 4.11969 5.39314 4.67213 4.6967 5.36857C4.00026 6.06501 3.44781 6.89181 3.0709 7.80175C2.69399 8.71169 2.5 9.68696 2.5 10.6719Z"
                            stroke="#E14A55" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                        <path d="M8.33301 12.3382L11.6663 9.00488" stroke="#E14A55" strokeWidth="2"
                              strokeLinecap="round" strokeLinejoin="round"></path>
                        <path d="M8.33268 9.00488L11.666 12.3382" stroke="#E14A55" strokeWidth="2"
                              strokeLinecap="round" strokeLinejoin="round"></path>
                    </g>
                    <defs>
                        <clipPath id="clip0_599_4955">
                            <rect width="20" height="20" fill="white" transform="translate(0 0.671875)"></rect>
                        </clipPath>
                    </defs>
                </svg>
            </ErrorIcon>}

		</PromocodeInputWrapper>}


		{isError && <PromocodeError>Промокод недействителен</PromocodeError>}

		{!store.payment?.discount && <PromocodeButton onClick={sendPromocode}>Применить</PromocodeButton> }

		{ store.payment?.discount && <PromocodeDiscountSuccess>
            <PromocodeSuccessTitle>Промокод на скидку { store.payment?.discount * 100 }% успешно применен !</PromocodeSuccessTitle>

            <PromocodeSuccessView><span>{ store.payment?.promocode }</span><SuccessPromoIcon/></PromocodeSuccessView>
        </PromocodeDiscountSuccess>}
	</PromocodeEnterWrapper>
}
