import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
	PaymentContent,
	PaymentContentCenter,
	PaymentItemWrapper,
	PaymentHeader,
	PaymentStyled,
	PaymentTitle,
	OrderDescriptionIconStyled,
	OrderGiftIconStyled,
	QrCodeIconStyled,
	QrCodeWrapper,
	FooterCopyright,
	PaymentBackdrop,
	SbpButtonWrapper,
	OrderRulesWrapper,
	PaymentEndStateWrapper, PaymentStateIconWrapper, PaymentReceipt,
	PaymentStateTitle, PaymentUnavailableCancelWrapper, LoaderWrapper, PaymentSberWrapper,
	SberPayError
} from './Payment.styled';
import logo from './static/logo.svg';
import { PaymentStore } from './payment.store';
import { OrderAmount } from './components/OrderAmount/OrderAmount.component';
import { OrderItemToggle } from './components/OrderDescription/OrderDescription.component';
import { OrderApplyPromo } from './components/OrderApplyPromo/OrderApplyPromo.component';
import { OrderRules } from './components/OrderRules/OrderRules.component';
import { SbpButton } from './components/SbpButton/SbpButton.component';
import { observer } from 'mobx-react';
import { QrHowToComponent } from './components/QrHowTo/QrHowTo.component';
import { PaymentCancel } from './components/PaymentCancel/PaymentCancel.component';
import { isMobile } from '../utils/is-mobile';
import { SuccessPaidIcon } from '../core/icons/SuccessPaidIcon';
import { ReceiptIcon } from '../core/icons/ReceiptIcon';
import { OrderNumber } from './components/OrderNumber/OrderNumber.component';
import { UnavailablePaymentIcon } from '../core/icons/UnavailablePaymentIcon';
import { Loader } from '../core/icons/Loader';
import { SberPay } from './components/SberPay/SberPay.component';
import { createWidget } from '@sber-ecom-core/sberpay-widget';
import { useSearchParams } from 'react-router-dom';

const MAX_REQUESTS = 3;

// success – оплата успешно прошла, требуется отправить на успешный экран
// return – в сценарии оплаты произошла ошибка, требуется отправить на неуспешный экран
// cancel – Клиент нажал на кнопку «Отменить». Актуально только для мобильной версии. В десктопной версии виджет закрывается, промис разрешается со значением 'cancel'.

enum PAGE_STATE {
	SBERPAY_PROCESS,
	UNAVAILABLE,
	SUCCESS,
	NEW
};

function getStateOfPage(store: PaymentStore, sberPayStatus: string | null) {

	if (!store.isLoaded && !store.payment) {
		return PAGE_STATE.SBERPAY_PROCESS;
	} else if ((store.payment && store.payment.unavailablePaymentSystems) || (!store.payment && store.isLoaded)) {
		return PAGE_STATE.UNAVAILABLE;
	} else if (store.payment && (store.payment?.isPaid || sberPayStatus === 'success')) {
		return PAGE_STATE.SUCCESS;
	} else if (store.payment && ( sberPayStatus === 'return' || sberPayStatus  === 'cancel' || (!sberPayStatus && store.payment?.isLikeAsNew))) {
		return PAGE_STATE.NEW;
	}
}


export const Payment: FC<any> = observer(() => {
	const [store] = useState(() => new PaymentStore());
	const params = useParams();
	const [searchParams] = useSearchParams();

	const [isHideToggle, setIsHideToggle] = useState(false);
	const [counter, setCounter] = useState(0);

	const id = (params.id ?? 0) as number;

	const sberPayStatus = searchParams.get('state');

	async function puller(redirectUrl: string) {
		setCounter(counter + 1);
		const isNext = await store.getState(id, redirectUrl);
		if (isNext && counter < MAX_REQUESTS) {
			setTimeout(() => puller(redirectUrl), 2000);
		}
	}

	async function pullerByState(redirectUrl: string) {
		setCounter(counter + 1);
		const isNext = await store.getStateWithoutPayment(id, redirectUrl);
		if (isNext && counter < MAX_REQUESTS) {
			setTimeout(() => pullerByState(redirectUrl), 2000);
		}
	}

	useEffect(() => {
		const redirectUrl = searchParams.get('redirect_url') ?? '';
		setIsHideToggle(!isMobile());
		console.log(sberPayStatus);
		if (sberPayStatus === 'success') {
			pullerByState(redirectUrl);
		} else {
			store.getPayment(id, redirectUrl).then(() => {
				puller(redirectUrl);
			});
		}
	}, [store, id]);

	const getReceiptLink = () => {
		if (store.payment?.receipt_link) {
			return;
		}

		const redirectUrl = searchParams.get('redirect_url') ?? '';

		store.getPayment(id, redirectUrl).then(() => {
			if (store.payment?.receipt_link) {
				window.location.href = store.payment?.receipt_link;
			}
		});
	}

	const getSberPayWidget = async () => {
		// const widget = createWidget(process.env.REACT_APP_SBERPAY_ENV as any);
		
		const widget = createWidget('PRODUCTION');

		const redirectUrl = searchParams.get('redirect_url') ?? '';
		
		try {
			store.isSberPayProcess = true;
			store.isSberPayError = false;
			const { bankInvoiceId } = await store.getSberPayPayment(id, redirectUrl);

			if (bankInvoiceId) {
				widget?.open({
					bankInvoiceId,
					backUrl: window.location.href
				}).then((status) => {
					console.log('status', status);
					store.isSberPayProcess = false;
					const redirectUrl = searchParams.get('redirect_url') ?? '';
					store.getPayment(id, redirectUrl);
				});
			}
		} catch(e) {
			store.isSberPayProcess = false;
			store.isSberPayError = true;
		}
	}

	const state = getStateOfPage(store, sberPayStatus);

	return <PaymentStyled>
		<PaymentHeader isEndState={!store.payment?.isLikeAsNew}>
			<img alt='logo' src={logo} width={'188px'}/>
		</PaymentHeader>
		<PaymentContentCenter isEndState={!store.payment?.isLikeAsNew}>
			<PaymentTitle isEndState={!store.payment?.isLikeAsNew}>Оплата заказа</PaymentTitle>
			{ !store.isLoaded && <LoaderWrapper>
                <Loader/>
			</LoaderWrapper> }

			{ state === PAGE_STATE.SBERPAY_PROCESS && <PaymentEndStateWrapper>
				<PaymentStateTitle lowMargin={true}>Платеж в обработке, пожалуйста, подождите</PaymentStateTitle>
				</PaymentEndStateWrapper>}

			{ state === PAGE_STATE.UNAVAILABLE && <PaymentEndStateWrapper>
                <PaymentStateTitle lowMargin={true}>Заказ недоступен <br/> для оплаты</PaymentStateTitle>
                <PaymentStateIconWrapper>
                	<UnavailablePaymentIcon/>
				</PaymentStateIconWrapper>
                <PaymentUnavailableCancelWrapper>
					<PaymentCancel href={store.redirectUrl} >&lt;&nbsp;&nbsp;Вернуться назад</PaymentCancel>
				</PaymentUnavailableCancelWrapper>
			</PaymentEndStateWrapper> }

			{ store.payment && state === PAGE_STATE.SUCCESS &&
                <PaymentEndStateWrapper>
                    <OrderNumber id={store.payment?.order_id} shopName={store.payment?.russian_trade_name ?? ''}/>
					<PaymentStateTitle>Оплачен</PaymentStateTitle>
					<PaymentStateIconWrapper>
                    	<SuccessPaidIcon/>
					</PaymentStateIconWrapper>
					<PaymentReceipt onClick={getReceiptLink} href={store.payment?.receipt_link}> <ReceiptIcon/> Получить чек</PaymentReceipt>
					{ store.payment?.is_redirect && <PaymentCancel href={store.redirectUrl} >&lt;&nbsp;&nbsp;Вернуться назад</PaymentCancel>}
                </PaymentEndStateWrapper>
			}
			{ store.payment && state === PAGE_STATE.NEW && <PaymentContent>
                <PaymentBackdrop>
                    <OrderAmount amount={store.payment.amount} discountAmount={store.payment.discount_amount}
                                 shopName={store.payment.russian_trade_name}/>
                    <PaymentItemWrapper>
                        <OrderItemToggle title={'Состав заказа'} icon={<OrderDescriptionIconStyled/>}
                                         content={<div>{store.payment.buyer_description}</div>}/>
                    </PaymentItemWrapper>
                    <PaymentItemWrapper>
                        <OrderItemToggle isHideToggle={isHideToggle} title={'Промокод или сертификат'}
                                         icon={<OrderGiftIconStyled/>}
                                         content={<OrderApplyPromo store={store}/>}/>
                    </PaymentItemWrapper>
                </PaymentBackdrop>
                <PaymentBackdrop>
					{ store.payment.sbpAvailable && <>
						<OrderRulesWrapper>
							<OrderRules/>
						</OrderRulesWrapper>
						<SbpButtonWrapper>
							<SbpButton link={store.payment.nspk_link}/>
						</SbpButtonWrapper>
						<PaymentItemWrapper>
							<OrderItemToggle title={isHideToggle ? 'QR-код для оплаты по СБП' : 'Скрыть QR-код'}
											isHideToggle={isHideToggle} closedTitle={'Показать QR-код'}
											icon={<QrCodeIconStyled/>}
											startIsOpen={!isMobile()}
											content={<><QrCodeWrapper><img alt="QR-код для оплаты" width={'100%'}
																			src={store.payment.qr_link ? store.payment.qr_image : `data:image/png;base64, ${store.payment.qr_image}`}/></QrCodeWrapper>
												<QrHowToComponent/>
											</>}/>
						</PaymentItemWrapper>
					</>}
					{ store.payment.sberpayAvailable && <PaymentSberWrapper>
						<SberPay onClick={getSberPayWidget} isLoading={store.isSberPayProcess}/> 
						{ store.isSberPayError && <SberPayError>SberPay недоступен, попробуйте позже или воспользуйтесь другим способом оплаты</SberPayError> }
					</PaymentSberWrapper> }

					<PaymentCancel href={store.redirectUrl}>Отменить</PaymentCancel>
                </PaymentBackdrop>

            </PaymentContent>}
			<FooterCopyright>
				<span>© 2023 Московский филиал Туринвойс Пазарьери Электроник Хизметлер А.Ш.</span><br/>
				<span>ИНН 9909659693 КПП 774791001</span><br/>
				<span>Номер записи об аккредитации в РФ 10230002233 16.11.23 МИФНС47</span><br/>
				<span>123112, РОССИЯ, Г. МОСКВА, ПРЕСНЕНСКАЯ НАБ., Д. 12, ПОМ. 10/45</span><br/>
				<span>Круглосуточно: +7 499 325 4898 +90 212 705 36 02</span><br/>
				<span><a href="mailto:yardim@turinvoice.com">yardim@turinvoice.com</a></span><br/>
			</FooterCopyright>
		</PaymentContentCenter>
	</PaymentStyled>;
});
