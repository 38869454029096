import styled from "styled-components";

export const SberPayButton = styled.button<{ isLoading?: boolean; }>`
    border: 2px solid #21A038;
    text-align: center;
    color: #fff;
    background-color: #21A038;;
    height: 70px;
    width: 100%;
    border-radius: 12px;
    font-size: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    ${p => p.isLoading && `
        pointer-events: none;
        opacity: 0.6;
    `}
`;

export const SberPayIconWrapper = styled.div`
    margin: 0 6px 0 10px;
`;